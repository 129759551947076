import type {
  Collection,
  CollectionConnection,
  CountryCode,
  LanguageCode,
} from '@shopify/hydrogen/storefront-api-types';
import type {SanitySlug} from 'domains/global';
import groq from 'groq';
import {client} from 'domains/sanity';
import type {SanityImage} from 'domains/sanity';
import type {SanityLink} from 'domains/global';
import {LINK_INTERNAL} from 'domains/global';
import type {AppLoadContext} from '@remix-run/server-runtime';
import * as R from 'ramda';
export interface BFSCollection {
  handle: string;
  id: number;
  sort_value: string;
  template_suffix: string;
  title: string;
}

export interface BFSProductVariant {
  available: boolean;
  barcode?: any;
  compare_at_price?: number;
  compare_at_price_hkd?: number;
  compare_at_price_twd?: number;
  fulfillment_service: string;
  id: number;
  image: string;
  inventory_management: string;
  inventory_policy: string;
  inventory_quantity: number;
  merged_options: string[];
  presentment_prices: {
    compare_at_price?: number;
    price: {
      amount: string;
      currency_code: string;
    };
  }[];
  price: string;
  price_hkd: string;
  price_twd: string;
  sku: string;
  title: string;
}

export interface BFSProductType {
  available?: boolean;
  barcodes?: any[];
  body_html?: string;
  collections?: any;
  compare_at_price_max?: number;
  compare_at_price_max_hkd?: number;
  compare_at_price_max_twd?: number;
  compare_at_price_min?: number;
  compare_at_price_min_hkd?: number;
  compare_at_price_min_twd?: number;
  created_at?: string;
  handle?: string;
  html?: {
    theme_id: number;
    value: string;
  };
  id?: number;
  images?: {
    [key: number]: string;
  };
  images_info: {
    alt?: string;
    height: number;
    id: number;
    position?: number;
    src: string;
    width: number;
  }[];
  locations?: any[];
  media?: any;
  metafields?: any[];
  options_with_values?: {
    label: string;
    name: string;
    values?: {
      image: number;
      title: string;
    }[];
  }[];
  percent_sale_min?: number;
  percent_sale_min_hkd?: number;
  percent_sale_min_twd?: number;
  position?: number;
  price_max?: number;
  price_max_hkd?: number;
  price_max_twd?: number;
  price_min?: number;
  price_min_hkd?: number;
  price_min_twd?: number;
  product_category?: any;
  product_type?: string;
  published_at?: string;
  published_scope?: string;
  review_count?: number;
  review_ratings?: number;
  skus?: string[];
  tags?: string[];
  template_suffix?: string;
  title?: string;
  updated_at?: string;
  vendor?: string;
  variants?: any;
  weight_max?: number;
  weight_min?: number;
}

export interface BFSResponse {
  total_product: number;
  total_collection: number;
  total_page: number;
  from_cache: boolean;
  products: BFSProductType[];
  event: any;
  affected_by_merchandising: boolean;
  filter: {
    options: FilterOption[];
  };
}

export interface PriceRange {
  min: number;
  max: number;
}
export interface FilterOption {
  displayType: string;
  excludedValues: any[];
  filterOptionId: string;
  filterType: string;
  label: string;
  manualValues?: string[];
  position: number;
  prefix?: any;
  removeTextFilterValues: string;
  replaceTextFilterValues?: any;
  selectType: string;
  status: string;
  showMoreType?: string;
  showSearchBoxFilterMobile?: boolean;
  showSearchBoxFilterPC?: boolean;
  sortManualValues?: boolean;
  sortType: 'key-asc';
  tooltip?: any;
  valueType: string;
  values:
    | {
        doc_count: number;
        key: string;
        label?: string;
        handle?: string;
      }[]
    | PriceRange;
  isCollapseMobile: boolean;
  isCollapsePC: boolean;
}

export interface SanityShopifyCollection {
  _createdAt: string;
  _id: string;
  _rev: string;
  _type: 'collection';
  _updatedAt: string;
  store: Collection & {gid: string; imageUrl?: string; slug?: SanitySlug};
}

export interface SanityCollectionLevel {
  _key: string;
  _type: 'collection_group';
  associated_collections: {
    _key: string;
    _type: 'collection_level';
    collections: SanityCollectionLevel[];
  }[];
  this_collection: SanityShopifyCollection;
}

export interface SantiyCollectionLevel {
  _key: string;
  _type: 'collection_group';
  associated_collections: {
    _key: string;
    _type: 'collection_level';
    collections: SantiyCollectionLevel[];
  }[];
  this_collection: SanityShopifyCollection;
}

export interface SanityCollectionStructure {
  collections: SantiyCollectionLevel[];
}

export interface CollectionAdvertisement {
  ad_banner: SanityImage;
  ad_link: SanityLink;
  ad_is_active: boolean;
}

export async function useCollectionQuery(collectionHandle: string) {
  const query = groq`
  {
    "defaultAdvertisement": *[_type == "settings"][0]{
      productSettings {
        product_settings_main {
          "advertisement": advertisement {
            ...,
            "ad_link": ad_link[0]{
              ...,
              (_type == "linkInternal") => {
                ${LINK_INTERNAL}
              }
            }
          }
        }
      }
    },
    "collectionAdvertisement": *[_type == "collection" && store.slug.current == "${collectionHandle}"][0]{
      "advertisement": advertisement {
        ...,
        "ad_link": ad_link[0]{
          ...,
          (_type == "linkInternal") => {
            ${LINK_INTERNAL}
          }
        }
      }
    },
    "collectionStructure": *[_type == "collection_structure"][1]{
      "collections": collections[]{
        ...,
        "associated_collections": associated_collections[]{
          ...,
          "collections": shopify_collections[]{
            ...,
            "associated_collections": associated_collections[]{
          ...,
          "collections": shopify_collections[]{
            ...,
            "this_collection": this_collection->,
          }
        },
            "this_collection": this_collection->,
          }
        },
        "this_collection": this_collection->,
      }
    }
  }
`;
  return (await client.fetch(query)) as {
    collectionStructure: SanityCollectionStructure;
    collectionAdvertisement: {advertisement: CollectionAdvertisement};
    defaultAdvertisement: {
      productSettings: {
        product_settings_main: {advertisement: CollectionAdvertisement};
      };
    };
  };
}

export async function useDefaultAdsQuery() {
  const query = groq`
  {
    "defaultAdvertisement": *[_type == "settings"][0]{
      productSettings {
        product_settings_main {
          "advertisement": advertisement {
            ...,
            "ad_link": ad_link[0]{
              ...,
              (_type == "linkInternal") => {
                ${LINK_INTERNAL}
              }
            }
          }
        }
      }
    }
  }
`;
  return (await client.fetch(query)) as {
    defaultAdvertisement: {
      productSettings: {
        product_settings_main: {advertisement: CollectionAdvertisement};
      };
    };
  };
}

export const findNestedLevel = (
  data: SanityCollectionLevel[],
  gid: string,
  level: number = 1,
): number => {
  if (!data || !Array.isArray(data)) {
    return -1;
  }

  return data.reduce((acc: number, item: SanityCollectionLevel): number => {
    if (item?.this_collection && item?.this_collection?.store?.gid === gid) {
      return level;
    }

    if (item?.associated_collections) {
      const nestedLevel = findNestedLevel(
        item?.associated_collections.flatMap((ac) => ac.collections),
        gid,
        level + 1,
      );
      return nestedLevel !== -1 ? nestedLevel : acc;
    }

    return acc;
  }, -1);
};

export const gidExists = (
  data: SanityCollectionLevel[],
  gidToFind: string,
): boolean => {
  if (!data || !Array.isArray(data)) {
    return false;
  }

  return data.some((item) =>
    item?.this_collection?.store?.gid === gidToFind
      ? true
      : item?.associated_collections
      ? item?.associated_collections.some((ac) =>
          gidExists(ac.collections, gidToFind),
        )
      : false,
  );
};

export const findImmediateChildren = (
  data: SanityCollectionLevel[],
  collectionId: string,
): SanityCollectionLevel[] | null => {
  if (!data || !Array.isArray(data)) {
    return null;
  }

  const reducer = (
    result: SanityCollectionLevel[] | null,
    item: SanityCollectionLevel,
  ): SanityCollectionLevel[] | null => {
    if (result) {
      return result;
    }

    if (item?.this_collection?.store?.gid === collectionId) {
      return item?.associated_collections
        ? item?.associated_collections.flatMap((ac) => ac.collections)
        : [];
    }

    const nestedResult = item?.associated_collections
      ? item?.associated_collections.reduce(
          (acc: SanityCollectionLevel[] | null, ac) =>
            findImmediateChildren(ac.collections, collectionId) || acc,
          null as SanityCollectionLevel[] | null,
        )
      : null;

    return nestedResult;
  };

  return data.reduce(reducer, null as SanityCollectionLevel[] | null);
};

export const findSiblings = (
  data: SanityCollectionLevel[],
  targetId: string,
): SanityCollectionLevel[] | null => {
  if (!data || !Array.isArray(data)) {
    return null;
  }

  for (const item of data) {
    if (item?.this_collection?.store?.gid === targetId) {
      return data.filter(
        (sibling) => sibling.this_collection.store.id !== targetId,
      );
    }

    const siblings =
      item?.associated_collections &&
      findSiblings(
        item?.associated_collections.map((ac) => ac.collections).flat(),
        targetId,
      );
    if (siblings) {
      return siblings;
    }
  }

  return null;
};

export const findParents = (
  data: SanityCollectionLevel[],
  targetGid: string,
): SanityShopifyCollection[] => {
  if (!data || !Array.isArray(data)) {
    return [];
  }

  const foundParents: SanityShopifyCollection[] = data.flatMap((item) => {
    const collections = item?.associated_collections
      ? item?.associated_collections.flatMap((ac) => ac.collections)
      : [];

    if (
      collections.some(
        (collection) => collection?.this_collection?.store?.gid === targetGid,
      )
    ) {
      return [item?.this_collection, ...findParents(collections, targetGid)];
    }

    const foundParentsInNestedCollections = findParents(collections, targetGid);
    if (foundParentsInNestedCollections.length > 0) {
      return [item?.this_collection, ...foundParentsInNestedCollections];
    }

    return [];
  });

  return foundParents;
};

export const sortingOptionsZH = [
  {
    label: '預設',
    value: 'manual',
  },
  {
    label: '最暢銷產品',
    value: 'best-selling',
  },
  {
    label: '產品 (最新上架)',
    value: 'published-descending',
  },
  {
    label: '價錢 (低至高)',
    value: 'price-ascending',
  },
  {
    label: '價錢 (高至低)',
    value: 'price-descending',
  },
  {
    label: '產品 (A至Z)',
    value: 'title-ascending',
  },
  {
    label: '產品 (Z至A)',
    value: 'title-descending',
  },
];
export const sortingOptions = [
  {
    label: 'Manual',
    value: 'manual',
  },
  {
    label: 'Best Selling',
    value: 'best-selling',
  },
  {
    label: 'Newest to oldest',
    value: 'published-descending',
  },
  {
    label: 'Price ascending',
    value: 'price-ascending',
  },
  {
    label: 'Price descending',
    value: 'price-descending',
  },
  {
    label: 'Title ascending',
    value: 'title-ascending',
  },
  {
    label: 'Title descending',
    value: 'title-descending',
  },
];
export type FilterType = 'PAGE' | 'LIST' | 'SWATCH' | 'PRICE_RANGE' | 'REMOVE';

export function filterInputToParams(
  type: FilterType,
  rawInput: string | Record<string, any>,
  params: URLSearchParams,
  filterKey: string,
) {
  const input = typeof rawInput === 'string' ? JSON.parse(rawInput) : rawInput;
  switch (type) {
    case 'PAGE':
      params.set('page', input.nextPage);
      break;
    case 'PRICE_RANGE':
      const minPrice = new Intl.NumberFormat('zh-HK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .format(parseInt(input.price.min))
        .replace(/[,]/g, '');
      const maxPrice = new Intl.NumberFormat('zh-HK', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
        .format(parseInt(input.price.max))
        .replace(/[,]/g, '');
      const newSearchParamPrices = new URLSearchParams();
      for (const [key, value] of params.entries()) {
        if (key !== filterKey) {
          newSearchParamPrices.append(key, value);
        }
      }
      newSearchParamPrices.set('page', '1');
      newSearchParamPrices.append(filterKey, `${minPrice}:${maxPrice}`);
      return newSearchParamPrices;
    case 'SWATCH':
    case 'LIST':
      const allSelectedBrands = params.getAll(filterKey);
      if (!allSelectedBrands.includes(input.val)) {
        params.set('page', '1');
        params.append(filterKey, input.val);
      } else {
        const filteredBrands = allSelectedBrands.filter((b) => b !== input.val);
        const newSearchParams = new URLSearchParams();
        for (const [key, value] of params.entries()) {
          if (key !== filterKey) {
            newSearchParams.append(key, value);
          }
        }
        newSearchParams.set('page', '1');
        filteredBrands.map((brand) => newSearchParams.append(filterKey, brand));
        newSearchParams.set('page', '1');
        return newSearchParams;
      }
      break;
    case 'REMOVE':
      const allSelectedOptions = params.getAll(filterKey);

      const filteredOptions = allSelectedOptions.filter((b) => b !== input.val);
      const newSearchParams = new URLSearchParams();
      for (const [key, value] of params.entries()) {
        if (key !== filterKey) {
          newSearchParams.append(key, value);
        }
      }
      filteredOptions.map((option) =>
        newSearchParams.append(filterKey, option),
      );
      return newSearchParams;
  }

  return params;
}

export const PRODUCT_PER_PAGE = 39;
export const PRODUCT_PER_PAGE_WITHOUT_ADS = 40;

export function getCollectionsForBreadcrumb({
  collections,
  collectionStructure,
}: {
  collections: Collection[];
  collectionStructure: SanityCollectionStructure;
}) {
  const collectionsInHierarchy =
    collectionStructure &&
    collections?.filter((c) =>
      gidExists(collectionStructure.collections, c.id),
    );
  const collectionHierarachyData = collectionsInHierarchy?.map((c) => {
    return {
      id: c.id,
      title: c.title,
      handle: c.handle,
      level: collectionStructure
        ? findNestedLevel(collectionStructure.collections, c.id)
        : -1,
    };
  });
  const deepestLevelCollection = collectionHierarachyData.find(
    (c) =>
      c.level === Math.max(...collectionHierarachyData.map((c) => c.level)),
  );
  const allParentCollections =
    deepestLevelCollection &&
    findParents(collectionStructure.collections, deepestLevelCollection.id);
  const allParentCollectionsFormatted =
    allParentCollections &&
    allParentCollections.map((c) => ({
      id: c?.store?.gid,
      title: c.store.title,
      handle: c.store.handle,
    }));
  const collectionsToDispay = allParentCollectionsFormatted && [
    ...allParentCollectionsFormatted,
    deepestLevelCollection,
  ];

  return collectionsToDispay;
}

export async function getCollections({
  collectionIds,
  context,
}: {
  collectionIds: string[];
  context: AppLoadContext;
}): Promise<[Collection[] | null, any]> {
  if (context.storefront.i18n.language === 'EN') {
    try {
      const {collections} = await context.storefront.query<{
        collections: CollectionConnection['nodes'];
      }>(
        `#graphql
        query homeFeaturedCollections($gids: [ID!]!){
          collections: nodes(ids: $gids) {
            ...on Collection{
              id
              title
              handle
            }
          }
        }
      `,
        {
          variables: {
            gids: collectionIds,
          },
        },
      );
      return [collections, null];
    } catch (e) {
      console.log(e);
      return [null, e];
    }
  } else {
    try {
      const {collections} = await context.storefront.query<{
        collections: CollectionConnection['nodes'];
      }>(
        `#graphql
        query homeFeaturedCollections($country: CountryCode, $language: LanguageCode, $gids: [ID!]!)
        @inContext(country: $country, language: $language) {
          collections: nodes(ids: $gids) {
            ...on Collection{
              id
              title
              handle
            }
          }
        }
      `,
        {
          variables: {
            country: context.storefront.i18n.country,
            language: context.storefront.i18n.language,
            gids: collectionIds,
          },
        },
      );
      return [collections, null];
    } catch (e) {
      console.log(e);
      return [null, e];
    }
  }
}

export function getAllParentCollections({
  collectionStructure,
  collectionId,
}: {
  collectionStructure: SanityCollectionStructure;
  collectionId: string;
}) {
  const isCollectionInHiererachy =
    collectionStructure &&
    gidExists(
      collectionStructure.collections as SanityCollectionLevel[],
      collectionId,
    );

  const collectionLevel = isCollectionInHiererachy
    ? findNestedLevel(
        collectionStructure.collections as SanityCollectionLevel[],
        collectionId,
      )
    : -1;

  const allParentCollections =
    collectionLevel > 1 && collectionStructure
      ? findParents(
          collectionStructure.collections as SanityCollectionLevel[],
          collectionId,
        )
      : null;

  return allParentCollections;
}

export function getRelatedCollections({
  collectionStructure,
  collectionId,
}: {
  collectionStructure: SanityCollectionStructure;
  collectionId: string;
}) {
  const isCollectionInHiererachy =
    collectionStructure &&
    gidExists(collectionStructure.collections, collectionId);

  const collectionLevel = isCollectionInHiererachy
    ? findNestedLevel(collectionStructure.collections, collectionId)
    : -1;

  if (collectionLevel === 3) {
    return findSiblings(collectionStructure.collections, collectionId);
  }

  if (collectionLevel === 2) {
    return findSiblings(collectionStructure.collections, collectionId);
  }

  return collectionStructure?.collections?.find(
    (c) => c?.this_collection?.store?.gid === collectionId,
  )
    ? [
        collectionStructure?.collections?.find(
          (c) => c?.this_collection?.store?.gid === collectionId,
        ),
      ]
    : null;
}

export function extractGids(
  data: SanityCollectionLevel[] | SanityCollectionLevel,
): string[] {
  if (Array.isArray(data)) {
    return data.flatMap(extractGids);
  }

  if (typeof data === 'object' && data !== null) {
    return Object.entries(data).flatMap(([key, value]) => {
      if (key === 'gid' && typeof value === 'string') {
        return [value];
      }

      return extractGids(value);
    });
  }

  return [];
}
